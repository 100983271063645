import { Component } from '@angular/core';
import { SessionStorageService, NotificationService, UserService } from '@shared/services';

@Component({
  selector:      'feedback-popup',
  templateUrl: './feedback-popup.component.html',
})
export class FeedbackPopupComponent {
  private readonly COMPONENT_NAME: string = 'FeedbackPopup';
  url:     string = null;
  rate:    number = null;
  message: string = null;
  constructor(
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService,
    private userService:           UserService
  ) {}

  close(e = null): void {
    if (!e || !e.target.closest('.dynamic-modal-content')) this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
  }

  sendFeedback(): void {
    this.notificationService.wait();
    let params = {
      feature_name: 'PhotoTN OCR',
      rate:          this.rate,
      message:       this.message,
      url:           window.location.href
    };

    this.userService.sendFeedBack(params).subscribe(
      ()  => this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME),
      err => this.notificationService.alert(err),
      ()  => this.notificationService.close()
    );
  }

}

import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { NotificationService } from '@shared/services';
import { MileageMoneyDetailsComponent, NewActivityReportComponent, WorkingPeriodDetailsComponent } from 'app/components/time-tracking';

import { messages } from "@messages";

const SKIP_UNSAVE_POPUP        = 'skipConfirmationForUnsavedData';
const SKIP_NEW_AR_UNSAVE_POPUP = 'skipConfirmationForNewUnsavedAR';

@Injectable()
export class UnsavedGuard  {
  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) {}

  canDeactivate(target, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) {
    if (nextState.url.includes('/auth/') || nextState.url.includes('/wp-km/') || target.skipGuard) return true;
    else if (currentState.url === '/time-tracking/new-activity-report') return this.guardHandler(this.checkNewARRouteChange,     target, nextState, SKIP_NEW_AR_UNSAVE_POPUP);
    else if (currentState.url.includes('/time-tracking/wp-details/') && 
            !currentState.url.includes('/wp-km/'))                      return this.guardHandler(this.checkARDetailsRouteChange, target, nextState);
    else if (currentState.url.includes('/time-tracking/mm-details/') || 
             currentState.url.includes('/wp-km/'))                      return this.guardHandler(this.checkMMDetailsRouteChange, target, nextState);
    return false;
  }

  private guardHandler(checkFunction: Function, target: WorkingPeriodDetailsComponent, nextState: RouterStateSnapshot, storageName: string = SKIP_UNSAVE_POPUP): boolean {
    let skipUnsavedPopup = +localStorage.getItem(storageName);

    if (!skipUnsavedPopup) return checkFunction.call(this, target, nextState, storageName);
    return true;
  }

  private checkNewARRouteChange(target: NewActivityReportComponent, nextState: RouterStateSnapshot, storageName: string): boolean {
    if (target.activityReport?.time_frames?.length) {
      this.showPopup(nextState, storageName, target);
      return false;
    }
    return true;
  }

  private checkARDetailsRouteChange(target: WorkingPeriodDetailsComponent, nextState: RouterStateSnapshot, storageName: string): boolean {
    let changes;
    changes = !target.workingPeriod?.archived_at && this.detectChanges(target.workingPeriod, target.originWorkingPeriod); 
    changes = (target.originInternalNotes || '') !== (target.workingPeriod.internal_employee_notes || '');
    if (changes) {
      this.showPopup(nextState, storageName, target);
      return false;
    }
    return true;
  }

  private checkMMDetailsRouteChange(target: MileageMoneyDetailsComponent, nextState: RouterStateSnapshot, storageName: string): boolean {
    let changes = this.detectChanges(target.tempMileageReport, target.originMileageReport);
    if (target.editMode && changes) {
      this.showPopup(nextState, storageName, target);
      return false;
    }
    return true;
  }

  private showPopup(nextState: RouterStateSnapshot, storageName: string, target: any): void {
    this.notificationService.optionalNote(
      messages.note.sureWantToLeaveChangesWillBeLost,
      messages.note.doNotShowAnymore,
      () => localStorage.setItem(storageName, `${+!+localStorage.getItem(storageName)}`),
      () => {
        target.skipGuard = true;
        let url = nextState.url.split(";");
        this.router.navigate([url[0], this.parceRouteParams(url.slice(1))]);
      }
    );
  }

  private detectChanges(leftValue, rightValue): boolean {
    return leftValue && rightValue && JSON.stringify(leftValue.toJSON()) !== JSON.stringify(rightValue.toJSON());
  }

  private parceRouteParams(url: string[]) {
    let routeParams = {};
    if (url && url.length) {
      for (let i = 0; i < url.length; i++) {
        let param = url[i].split("=");
        routeParams[param[0]] = param[1];
      }
    }
    return routeParams;
  }

}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor( ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError(err => {
        let error;
        if (err instanceof HttpErrorResponse) {
          if (err.error && err.error.errors && err.error.errors.find(e => e.code === 'illegal_role' && (err.status === 401 || err.status === 403))) error = err.error.errors;
          else if (err.status === 403) error = 'Aktion verboten.';
          else if (err.status === 404) error = 'Eintrag nicht gefunden.';
          else if (err.status === 503 || err.status === 500) error = 'Die Applikation befindet sich im Moment im Wartungsmodus. Bitte versuchen Sie es in wenigen Minuten erneut.';
        }
        error = error || err.error.message || err.error.errors || err.statusText;
        if (!error || (typeof error === 'string' && error.toLowerCase().includes('unknown error'))) error = 'Hoppla, da ist ein Fehler aufgetreten. Bitte lade die Seite neu.';
        else if (Array.isArray(error) && !error.find(e => e.code === 'external_employee_locked'))   error = error.map(e => e.title).join('</br>');
        return throwError(error);
      })
    );
  }

}

import { Injectable } from "@angular/core";
import { formatDate } from "@angular/common";
import { Subject, Observable } from "rxjs";

import { SessionStorageService } from "./session-storage.service";
import { Toast } from "app/factories/toast.factory";
import { ToastModel } from "@shared/models";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  private subject = new Subject<ToastModel>();
  constructor( private sessionStorageService: SessionStorageService ) { }

  onNotify(): Observable<ToastModel> {
    return this.subject.asObservable();
  }

  private notify(data: Toast): void {
    if (this.sessionStorageService.isDevice) this.subject.next(data);
  }

  info(name: string, message: string): void {
    this.notify(new Toast({ name, message }));
  }

  warn(name: string, message: string): void {
    this.notify(new Toast({ name, message, type: 'warning' }));
  }

  openToast(string: string): void {
    let content = `<span class="tooltip-title">${string}</span>`;
    this.info('basic-tost', content);
  }

  openInfoSearch(): void {
    let content = `
    <div class="dashboard-toast">
      <span>Sie können mit folgenden Parametern suchen:</span>
      <ul>
        <li>Mitarbeitername</li>
        <li>Einsatzbezeichnung</li>
        <li>Zeitraum</li>
      </ul>
    </div>`
    this.info('dashboard-search', content);
  }

  openInfoSearchArchive(): void {
    let content = `
    <div class="dashboard-toast">
      <span>Wenn aktiv, werden folgende Parameter bei der Suche berücksichtigt:</span>
      <ul>
        <li>Mitarbeitername</li>
        <li>Einsatzbezeichnung</li>
        <li>Zeitraum</li>
      </ul>
    </div>`
    this.info('dashboard-search', content);
  }

  openUpdatedAt(): void {
    let content = `
    <div class="dashboard-toast">
      <span>Freigegebene und abgelehnte Tätigkeitsnachweise</span>
    </div>`
    this.info('archive-updated-at', content);
  }

  statusToast(message, type): void {
    let content = `<span>${message}</span>`
    this.info(`status-toast-${type}`, content);
  }

  pdStatusToast(message, type): void {
    let content = `<span>${message}</span>`
    this.info(`pd-status-toast-${type}`, content);
  }

  openIntenalCheckToast(time, user) {
    let content = `
    <span>
      <span>Intern geprüft </span>
      <span><strong>${formatDate(time, 'HH:mm dd.MM.yyyy', 'de')}</strong> von </span>
      <span><strong>${user.first_name} ${user.last_name}</strong></span>
    </span>`
    this.info(`internal-check-toast-${user.id}`, content);
  }

  customerReleasedToast(message) {
    let content = `<span>${message}</span>`
    this.info(`customer-released-toast`, content);
  }

  openPreferences() {
    let content = `<span>Sie erhalten eine E-Mail Benachrichtigung, wenn Sie Tätigkeitsnachweise nicht zu dem definierten Zeitpunkt freigegeben haben.</span>`
    this.info(`preferences-toast`, content);
  }

  openHolidays(holidays) {
    let content = `
    <span>
      <span>${holidays.map(h => h.name).join(',</span></br><span>')}</span>
    </span>`
    this.info(`holidays-toast`, content);
  }

  newActivityReportToast() {
    let content = '<span class="tooltip-title">Um einen TN erstellen zu können, muss der EBS in der App bestätigt sein und der Kunde das Kundenportal nutzen.</span>';
    this.info(`ar-new-toast`, content);
  }

  exportExcelFilterToast() {
    let content = '<span class="tooltip-title">Sie können hier zusätzlich die Arbeitszeiten Ihrer Tempton Mitarbeiter filtern.</span>';
    this.info(`excel-filter-toast`, content);
  }

}

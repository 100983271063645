import { formatDate } from '@angular/common';
import {
  ActivityReportBasicModel,
  ActivityReportBasicWithTimeFramesModel,
  ActivityReportForSubmitModel,
  ActivityReportSimplifiedModel,
  ActivityReportExtendedModel,
  DailyReportModel,
  CustomerNotificationModel,
  ZipCodeModel,
  RecognizedTimeFrameModel,
  RecognizedActivityReportModel
} from '@shared/models';

import {
  Assignment,
  CustomerNotification,
  DailyReport,
  ExternalEmployee,
  Employee,
  Customer,
  InternalEmployee,
  Holiday
} from '@shared/factories';

import { FormatDatesService } from "@shared/services";

export class ActivityReportBasic {
  id:                number;
  start_date:        Date;
  end_date:          Date;

  calendar_week:     string;
  date:              string;

  mileage_money:     boolean;

  assignment:        Assignment;
  external_employee: ExternalEmployee;
  constructor(data: any) {
    this.id                = data.id || null;
    this.start_date        = FormatDatesService.parseDate(FormatDatesService.parseDate(data.start_date).setHours(0,0,0,0));
    this.end_date          = FormatDatesService.parseDate(FormatDatesService.parseDate(data.end_date).setHours(23,59,59));

    this.calendar_week     = FormatDatesService.calendarWeek(this.start_date);
    this.date              = FormatDatesService.period(this.start_date, this.end_date);

    this.mileage_money     = data.mileage_money !== undefined ? data.mileage_money : null;

    this.assignment        = this.checkIfInstance(data.assignment, Assignment);
    this.external_employee = this.checkIfInstance(data.external_employee, ExternalEmployee);
  }

  toJSON(): ActivityReportBasicModel {
    return {
      id:                this.id                          ? this.id                         : null,
      start_date:        this.start_date                  ? this.start_date.toISOString()   : null,
      end_date:          this.end_date                    ? this.end_date.toISOString()     : null,
      assignment:        this.assignment                  ? this.assignment.toJSON()        : null,
      external_employee: this.external_employee           ? this.external_employee.toJSON() : null,
      mileage_money:     this.mileage_money !== undefined ? this.mileage_money              : null
    };
  }

  checkIfInstance(value: any, instance: any): any {
    return value ? value instanceof instance ? value : this.newInstance(value, instance) : null;
  }

  newInstance(value: any, instance: any): any {
    return new instance(value);
  }

}

class ActivityReportBasicWithTimeFrames extends ActivityReportBasic {
  original_time_frames: DailyReportModel[];
  time_frames:          DailyReport[];
  constructor(data: any) {
    super(data);
    this.original_time_frames =  this.arrayNotEmpty(data.original_time_frames) || [];
    this.time_frames          = (this.arrayNotEmpty(data.original_time_frames) || []).map(tf => new DailyReport(tf));
  }

  get holidaysList(): Holiday[] {
    let hol = [];
    this.activeTimeFrames.filter(tf => tf.holidays.length).forEach(tf => {
      hol = [...hol, ...tf.holidays];
    });
    return hol;
  }

  get activeTimeFrames(): DailyReport[] {
    return this.time_frames.filter(tf => !tf.deleted && !tf.placeholder);
  }

  get arTimeWithoutPauses(): number {
    return this.activeTimeFrames.reduce((sum: number, val: DailyReport) => {
      return +sum + +val.totalDurationExcludingPauses;
    }, 0);
  }

  get arTimeWithPauses(): number {
    return this.activeTimeFrames.reduce((sum: number, val: DailyReport) => {
      return +sum + +val.totalDuration;
    }, 0);
  }

  toJSON(): ActivityReportBasicWithTimeFramesModel {
    return Object.assign(super.toJSON(), {
      original_time_frames: this.arrayNotEmpty(this.original_time_frames) ? this.original_time_frames : [],
    });
  }

  arrayNotEmpty(array: any[]): any[] {
    return array?.length ? array : null;
  }

}

export class ActivityReportNew extends ActivityReportBasicWithTimeFrames {
  state_iso: string;
  constructor(data: any, zip_code?: ZipCodeModel) {
    super(data);
    this.state_iso = data.state_iso || zip_code && zip_code.state_iso || null;
  }

  toSubmitJSON(): ActivityReportForSubmitModel {
    return {
      submitted_at:          (new Date).toISOString(),
      start_date:            formatDate(this.start_date, 'yyyy-MM-dd','de'),
      end_date:              formatDate(this.end_date,   'yyyy-MM-dd','de'),
      external_employee_id:  this.external_employee.id,
      assignment_id:         this.assignment.id,
      status:                2,
      submit_with_timestamp: 1,
      mileage_money:         this.mileage_money !== undefined ? this.mileage_money : null,
      time_frames:           this.activeTimeFrames.map(tf => tf.toSubmitJSON())
    };
  }
}

export class ActivityReportSimplified extends ActivityReportBasicWithTimeFrames {
  created_at:                 Date;
  archived_at:                Date;
  checked_time_at:            Date;
  customer_reviewed_at:       Date;

  customer_review:            string;
  life_state:                 string;

  export_comment:             string;
  export_state:               string;
  export_status:              number;

  split_child_id:             number;
  split_parent_id:            number;

  holidays:                   string[];
  with_attachment:            boolean;
  mileage_money_report_id:    number;

  approvedByCustomer:         boolean;
  rejectedByCustomer:         boolean;
  approvedByCustomerMobile:   boolean;
  rejectedByCustomerMobile:   boolean;
  approvedByCustomerPortals:  boolean;
  rejectedByCustomerPortals:  boolean;

  awaitingForCustomer:        boolean;
  approvedByInternal:         boolean;
  rejectedByInternal:         boolean;

  belongsToInternalLocations: boolean;
  constructor(data: ActivityReportSimplifiedModel) {
    super(data);
    this.created_at                 = FormatDatesService.parseDate(data.created_at);
    this.archived_at                = FormatDatesService.parseDate(data.archived_at);
    this.checked_time_at            = FormatDatesService.parseDate(data.checked_time_at);
    this.customer_reviewed_at       = FormatDatesService.parseDate(data.customer_reviewed_at);

    this.customer_review            = data.customer_review ?  data.customer_review : null;
    this.life_state                 = data.life_state      ?  data.life_state      : null;

    this.export_comment             = data.export_comment  ?  data.export_comment  : null;
    this.export_status              = data.export_status   ? +data.export_status   : null;
    this.export_state               = data.export_state    ?  data.export_state    : null;

    this.split_child_id             = data.split_child_id;
    this.split_parent_id            = data.split_parent_id;

    this.holidays                   = data.holidays && data.holidays.length ?  data.holidays                : [];
    this.with_attachment            = data.with_attachment                  ?  data.with_attachment         : null;
    this.mileage_money_report_id    = data.mileage_money_report_id          ? +data.mileage_money_report_id : null;

    this.approvedByCustomerMobile   = this.customer_review === 'released_by_mobile';
    this.rejectedByCustomerMobile   = this.customer_review === 'rejected_by_mobile';
    this.approvedByCustomerPortals  = this.customer_review === 'released_by_portal';
    this.rejectedByCustomerPortals  = this.customer_review === 'rejected_by_portal';

    this.approvedByCustomer         = this.approvedByCustomerMobile || this.approvedByCustomerPortals;
    this.rejectedByCustomer         = this.rejectedByCustomerMobile || this.rejectedByCustomerPortals;

    this.awaitingForCustomer        = this.life_state      === 'waiting_customer_approval';
    this.approvedByInternal         = this.life_state      === 'approved_by_internal';
    this.rejectedByInternal         = this.life_state      === 'rejected_by_internal';

    this.belongsToInternalLocations = (data as ActivityReportSimplifiedModel).belongs_to_internal_locations;
  }

  toJSON(): ActivityReportSimplifiedModel {
    return Object.assign(super.toJSON(), {
      created_at:                    this.created_at                 ? this.created_at.toISOString()           : null,
      checked_time_at:               this.checked_time_at            ? this.checked_time_at.toISOString()      : null,
      archived_at:                   this.archived_at                ? this.archived_at.toISOString()          : null,
      customer_reviewed_at:          this.customer_reviewed_at       ? this.customer_reviewed_at.toISOString() : null,

      customer_review:               this.customer_review            ? this.customer_review                    : null,
      life_state:                    this.life_state                 ? this.life_state                         : null,

      export_status:                 this.export_status              ? this.export_status+''                   : null,
      export_comment:                this.export_comment             ? this.export_comment                     : null,
      export_state:                  this.export_state               ? this.export_state                       : null,

      split_child_id:                this.split_child_id             ? this.split_child_id                     : null,
      split_parent_id:               this.split_parent_id            ? this.split_parent_id                    : null,

      mileage_money_report_id:       this.mileage_money_report_id    ? this.mileage_money_report_id            : null,
      with_attachment:               this.with_attachment            ? this.with_attachment                    : null,
      holidays:                      this.holidays                   ? this.holidays                           : null,

      belongs_to_internal_locations: this.belongsToInternalLocations ? this.belongsToInternalLocations         : null
    });
  }
}

export class ActivityReportExtended extends ActivityReportSimplified {
  created_by:                  Employee;
  archived_by:                 Employee;
  checked_time_by:             InternalEmployee;
  reviewed_by_customer:        Customer;

  external_employee_signature: string;
  customer_signature:          string;
  signer:                      string;

  attachment_url:              string;
  attachment_rotation_angle:   number;
  submit_with_timestamp:       number;

  mileage_money:               boolean;

  external_employee_notes:     string;
  internal_employee_notes:     string;
  customer_notes:              string;


  daysBeforeAutoArchive:       number;
  autoArchiveAt:               Date;

  original_end_date:           Date;
  out_of_assignment_at:        Date;

  corrected_time_frames:       DailyReportModel[];

  signature_confirmation?:     CustomerNotificationModel;
  customer_notifications:      CustomerNotification[];
  state_iso:                   any;

  archivedBySystem:            boolean;
  deletedByInternal:           boolean;
  deletedByCustomer:           boolean;

  failedErpReport:             boolean;  

  recognizedTimeFrames:        RecognizedTimeFrame[];
  validRecognizedTimeFrames:   RecognizedTimeFrame[];

  ocrMetaTable:                RecognizedTimeFrameMeta[];
  ocrImageWidth:               number;
  ocrImageHeight:              number;
  ocrUsed:                     boolean;
  constructor(data: ActivityReportExtendedModel, notifications?: CustomerNotificationModel[], zip_code?: ZipCodeModel, recognized_working_period?: RecognizedActivityReportModel) {
    super(data);
    this.created_by                  = super.checkIfInstance(data.created_by,           Employee);
    this.archived_by                 = super.checkIfInstance(data.archived_by,          Employee);
    this.checked_time_by             = super.checkIfInstance(data.checked_time_by,      InternalEmployee);
    this.reviewed_by_customer        = super.checkIfInstance(data.reviewed_by_customer, Customer);

    this.external_employee_signature = data.external_employee_signature ? data.external_employee_signature : null;
    this.customer_signature          = data.customer_signature          ? data.customer_signature          : null;
    this.signer                      = data.signer                      ? data.signer                      : null;

    this.attachment_url              = data.attachment_url              ? data.attachment_url              : null;
    this.attachment_rotation_angle   = data.attachment_rotation_angle   ? data.attachment_rotation_angle   : null;
    this.submit_with_timestamp       = data.submit_with_timestamp !== undefined ? data.submit_with_timestamp : 1;   // check

    this.external_employee_notes     = data.external_employee_notes     ? data.external_employee_notes     : null;
    this.internal_employee_notes     = data.internal_employee_notes     ? data.internal_employee_notes     : null;
    this.customer_notes              = data.customer_notes              ? data.customer_notes              : null;

    this.daysBeforeAutoArchive       = data.days_before_auto_archive;
    this.autoArchiveAt               = this.parceAutoArchiveAt();

    this.original_end_date           = data.original_end_date ? FormatDatesService.parseDate(FormatDatesService.parseDate(data.original_end_date).setHours(23,59,59)) : null;

    this.corrected_time_frames       = super.arrayNotEmpty(data.corrected_time_frames) || [];
    this.time_frames                 = super.arrayNotEmpty(data.time_frames) ? super.arrayNotEmpty(data.time_frames).map(tf => new DailyReport(tf)) : this.collectTimeFrames();

    this.customer_notifications      = super.arrayNotEmpty(notifications) ? notifications.map(n => new CustomerNotification(n)).sort((a,b) => b.created_at.getTime() - a.created_at.getTime()) :
                                       data.signature_confirmation        ? [new CustomerNotification(data.signature_confirmation)] : [];
    this.state_iso                   = data.state_iso || zip_code && zip_code.state_iso || null;

    this.archivedBySystem            = this.life_state && this.life_state === 'archived_by_system';
    this.deletedByInternal           = this.life_state && this.life_state === 'rejected_by_internal';
    this.deletedByCustomer           = this.life_state && this.life_state === 'deleted_by_customer';

    this.failedErpReport             = this.belongsToInternalLocations && this.approvedByInternal && this.id && this.export_state === 'failed_export';

    this.recognizedTimeFrames        = (this.arrayNotEmpty(data.recognizedTimeFrames) || recognized_working_period?.recognized_time_frames || [])
                                       .map(tf => new RecognizedTimeFrame(tf));
    this.validRecognizedTimeFrames   = this.recognizedTimeFrames?.filter(rtf => rtf.date) || [];

    this.ocrMetaTable                = this.recognizedTimeFrames.length ? [
      this.ocrPolygonsAvailable(this.recognizedTimeFrames[0], 'calendarWeek'),
      ...this.recognizedTimeFrames.reduce((sum, rtf) => {
        let temp = [
          ...sum,
          this.ocrPolygonsAvailable(rtf, 'date'),
          this.ocrPolygonsAvailable(rtf, 'startedTime'),
          this.ocrPolygonsAvailable(rtf, 'endedTime'),
          this.ocrPolygonsAvailable(rtf, 'pause'),
          this.ocrPolygonsAvailable(rtf, 'worked'),
        ];
        return temp;
      }, [])
    ].filter(val => val?.polygon) : null;

    let imageSize = this.recognizedTimeFrames?.length ? this.recognizedTimeFrames[0].imageSize : null;
    this.ocrImageWidth  = imageSize ? imageSize[0] : null;
    this.ocrImageHeight = imageSize ? imageSize[1] : null;

    this.ocrUsed = data.ocrUsed || !!recognized_working_period?.internal_used_at;
  }

  private ocrPolygonsAvailable(rtf: RecognizedTimeFrame, metaField: string): RecognizedTimeFrameMeta {
    return rtf[metaField+'Meta'].polygon ? Object.assign(rtf[metaField+'Meta']) : null;
  }

  private collectTimeFrames(): DailyReport[] {
    const time_frames = this.original_time_frames.map(tf => {
      if (this.corrected_time_frames.length) {
        const update = this.corrected_time_frames.find(c_tf => c_tf.original_time_frame_id === tf.id);
        if (update) return new DailyReport(tf, update);
        else return new DailyReport(tf, null, false, true);
      } else return new DailyReport(tf);
    }).filter(tf => tf);
    const newTimeFrames: DailyReport[] = this.corrected_time_frames.filter(c_tf => !c_tf.original_time_frame_id).map(c_tf => new DailyReport(c_tf, null, true));
    return [...time_frames, ...newTimeFrames];
  }

  get activeTimeFrames(): DailyReport[] {
    return this.time_frames.filter(tf => !tf.deleted);
  }

  private parceAutoArchiveAt(): Date {
    if (this.daysBeforeAutoArchive === null) return null;
    if (isNaN(this.daysBeforeAutoArchive))   return null;
    let date = new Date();
    date.setDate(date.getDate() + this.daysBeforeAutoArchive);
    date.setHours(0,0,0);
    return date;
  }

  get ocrPreselected(): boolean { return !!this.activeTimeFrames.find(tf => tf.ocrPreselected); }

  toJSON(): ActivityReportExtendedModel {
    return Object.assign(super.toJSON(), {
      created_by:                    this.created_by                               ? this.created_by.toJSON()                           : null,
      archived_by:                   this.archived_by                              ? this.archived_by.toJSON()                          : null,
      checked_time_by:               this.checked_time_by                          ? this.checked_time_by.toJSON()                      : null,
      reviewed_by_customer:          this.reviewed_by_customer                     ? this.reviewed_by_customer.toJSON()                 : null,

      external_employee_signature:   this.external_employee_signature              ? this.external_employee_signature                   : null,
      customer_signature:            this.customer_signature                       ? this.customer_signature                            : null,
      signer:                        this.signer                                   ? this.signer                                        : null,

      attachment_url:                this.attachment_url                           ? this.attachment_url                                : null,
      attachment_rotation_angle:     this.attachment_rotation_angle                ? this.attachment_rotation_angle                     : null,
      submit_with_timestamp:         this.submit_with_timestamp                    ? this.submit_with_timestamp                         : null,

      mileage_money_report_id:       this.mileage_money_report_id                  ? this.mileage_money_report_id                       : null,

      external_employee_notes:       this.external_employee_notes                  ? this.external_employee_notes                       : null,
      internal_employee_notes:       this.internal_employee_notes                  ? this.internal_employee_notes                       : null,
      customer_notes:                this.customer_notes                           ? this.customer_notes                                : null,

      days_before_auto_archive:      this.daysBeforeAutoArchive !== null           ? this.daysBeforeAutoArchive                         : null,

      original_end_date:             this.original_end_date                        ? this.original_end_date.toISOString()               : null,
      out_of_assignment_at:          this.out_of_assignment_at                     ? this.out_of_assignment_at.toISOString()            : null,

      original_time_frames:          this.original_time_frames?.length             ? this.original_time_frames                          : [],
      corrected_time_frames:         this.corrected_time_frames?.length            ? this.corrected_time_frames                         : [],
      time_frames:                   this.time_frames?.length                      ? this.time_frames.map(tf => tf.toJSON())            : [],

      customer_notifications:        this.customer_notifications                   ? this.customer_notifications.map(cn => cn.toJSON()) : null,
      state_iso:                     this.state_iso                                ? this.state_iso                                     : "",
      recognizedTimeFrames:          this.recognizedTimeFrames?.length             ? this.recognizedTimeFrames.map(tf => tf.toJSON())   : null,
      ocrUsed:                       this.ocrUsed                                  ? this.ocrUsed                                       : null,
    });
  }

}

export class RecognizedTimeFrame {
  id:               number;
  imageSize:        number[];
  calendarWeek:     number;

  date:             Date;
  startedTime:      Date;
  endedTime:        Date;
  pause:            number;
  worked:           number;

  calendarWeekMeta: RecognizedTimeFrameMeta;
  dateMeta:         RecognizedTimeFrameMeta;
  startedTimeMeta:  RecognizedTimeFrameMeta;
  endedTimeMeta:    RecognizedTimeFrameMeta;
  pauseMeta:        RecognizedTimeFrameMeta;
  workedMeta:       RecognizedTimeFrameMeta;

  metaTable:        RecognizedTimeFrameMeta[];

  constructor(data: RecognizedTimeFrameModel) {
    this.id               = data.id                                    ? data.id                                                                                    : null;
    this.imageSize        = data.image_size                            ? data.image_size                                                                            : null;
    this.calendarWeek     = data.calendar_week                         ? +data.calendar_week                                                                        : null;

    this.date             = this.isConfidentData(data, 'date')         ? FormatDatesService.isValidDate(FormatDatesService.parseDate(data.date))                    : null;
    this.startedTime      = this.isConfidentData(data, 'started_time') ? FormatDatesService.isValidDate(FormatDatesService.parseTime(this.date, data.started_time)) : null;
    this.endedTime        = this.isConfidentData(data, 'ended_time')   ? FormatDatesService.isValidDate(FormatDatesService.parseTime(this.date, data.ended_time))   : null;
    this.pause            = this.isConfidentData(data, 'pause')        ? FormatDatesService.getTimeStringDuration(data.pause)                                       : null;
    this.worked           = this.isConfidentData(data, 'worked')       ? FormatDatesService.getStringDuration(data.worked)                                          : null;

    // if (!this.startedTime && this.endedTime   && this.worked) this.startedTime = new Date(this.endedTime.getTime()   - this.worked - this.worked);
    // if (!this.endedTime   && this.startedTime && this.worked) this.endedTime   = new Date(this.startedTime.getTime() + this.worked + this.pause);

    this.calendarWeekMeta = this.newRecognizedTimeFrameMetaHandler(data, 'calendar_week', this.date);
    this.dateMeta         = this.newRecognizedTimeFrameMetaHandler(data, 'date',          this.date);
    this.startedTimeMeta  = this.newRecognizedTimeFrameMetaHandler(data, 'started_time',  this.date);
    this.endedTimeMeta    = this.newRecognizedTimeFrameMetaHandler(data, 'ended_time',    this.date);
    this.pauseMeta        = this.newRecognizedTimeFrameMetaHandler(data, 'pause',         this.date);
    this.workedMeta       = this.newRecognizedTimeFrameMetaHandler(data, 'worked',        this.date);
  }

  toJSON(): RecognizedTimeFrameModel {
    return {
      id:                 this.id               ? this.id                                                : null,
      image_size:         this.imageSize        ? this.imageSize                                         : null,
      calendar_week:      this.calendarWeek     ? this.calendarWeek+''                                   : null,

      date:               this.date             ? this.date.toISOString()                                : null,
      started_time:       this.startedTime      ? FormatDatesService.toTimeString(this.startedTime)      : null,
      ended_time:         this.endedTime        ? FormatDatesService.toTimeString(this.endedTime)        : null,
      pause:              this.pause            ? FormatDatesService.parseNumberToDateString(this.pause) : null,
      worked:             this.worked           ? FormatDatesService.parseNumberToString(this.worked)    : null,

      calendar_week_meta: this.calendarWeekMeta ? this.calendarWeekMeta                                  : null,
      date_meta:          this.dateMeta         ? this.dateMeta                                          : null,
      started_time_meta:  this.startedTimeMeta  ? this.startedTimeMeta                                   : null,
      ended_time_meta:    this.endedTimeMeta    ? this.endedTimeMeta                                     : null,
      pause_meta:         this.pauseMeta        ? this.pauseMeta                                         : null,
      worked_meta:        this.workedMeta       ? this.workedMeta                                        : null,
    }
  }

  private newRecognizedTimeFrameMetaHandler(data: RecognizedTimeFrameModel, metaField: string, frameDate: Date): RecognizedTimeFrameMeta {
    return data[metaField+'_meta'] ? new RecognizedTimeFrameMeta(data, metaField, frameDate) : null;
  }

  private isConfidentData(data: RecognizedTimeFrameModel, field: string): any {
    return data[field] && data[`${field}_meta`] && data[`${field}_meta`].confidence > 0.75;
  }

}

export class RecognizedTimeFrameMeta {
  metaField:  string;
  frameDate:  Date;
  content:    string;
  confidence: number;
  polygon:    number[];
  constructor(data: RecognizedTimeFrameModel, metaField: string, frameDate: Date) {
    this.metaField  = metaField;
    this.frameDate  = frameDate;
    this.content    = data[metaField+'_meta']?.content                ? data[metaField+'_meta'].content        : null;
    this.confidence = data[metaField+'_meta']?.confidence ? frameDate ? data[metaField+'_meta'].confidence : 0 : null;
    this.polygon    = data[metaField+'_meta']?.polygon                ? data[metaField+'_meta'].polygon        : null;
  }
}
